export interface HelperLink {
  id: number;
  title: string;
}

export const helperLinks: HelperLink[] = [
  {
    id: 1,
    title: "Opprette profil på Minside",
  },
  {
    id: 2,
    title: "Kursdeltakere og studenter",
  },
  {
    id: 3,
    title: "Ressurser og veiledere",
  },
];

export const refundHelperLinks: HelperLink[] = [
  {
    id: 1,
    title: "Hvordan registrere refusjonskrav",
  },
  {
    id: 2,
    title: "Skjema tapt arbeidsfortjeneste",
  },
];

export const districtsSupportInfo = [
  {
    id: 1,
    district: "Studieforbundet AOF Norge",
    email: "support-minside@aof.no",
    phone: "24 07 60 70",
  },

  {
    id: 2,
    district: "AOF Østfold",
    email: "ostfold@aof.no",
    phone: "69 13 04 10",
  },

  {
    id: 3,
    district: "AOF Vestlandet-Agder",
    email: "support@aof-va.no",
    phone: "52 70 90 00",
  },
];
