
import { defineComponent } from "@vue/composition-api";
import { helperLinks, refundHelperLinks, districtsSupportInfo } from "@/shared/constants/HelpPageData";
import BaseLayout from "@/components/shared/layout/BaseLayout.vue";

export default defineComponent({
  name: "TheHelpPage",
  components: { BaseLayout },
  setup() {
    const openEmailWindow = (email: string): void => {
      window.open(`mailto:${email}?subject=Kundeservice`, "_self");
    };

    return {
      helperLinks,
      refundHelperLinks,
      openEmailWindow,
      districtsSupportInfo,
      navigateBack: () => history.back(),
    };
  },
});
