var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseLayout', {
    attrs: {
      "displayBreadCrumbs": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('v-icon', {
          on: {
            "click": _vm.navigateBack
          }
        }, [_vm._v("mdi-arrow-left")]), _vm._v(" Hjelpeside ")];
      },
      proxy: true
    }])
  }, [[_c('div', {
    staticStyle: {
      "padding": "30px"
    }
  }, [_c('h3', {
    staticStyle: {
      "margin-bottom": "20px"
    }
  }, [_vm._v(" På denne siden finner du informasjon om brukerveiledninger til Minside og kontaktinformasjon for support. ")]), _c('div', [_c('h4', [_vm._v("Minside – Universum - brukerveiledninger")]), _c('ul', {
    staticClass: "list-style"
  }, _vm._l(_vm.helperLinks, function (link) {
    return _c('li', {
      key: link.id
    }, [_c('a', {
      staticClass: "hover-underline-animation",
      attrs: {
        "href": "https://www.aof.no/kontakt/brukerveiledning-og-skjema/",
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(link.title))])]);
  }), 0)]), _c('div', {
    staticStyle: {
      "margin-top": "10px",
      "margin-bottom": "15px"
    }
  }, [_c('h4', [_vm._v("Refusjonskrav - Reise og stipend")]), _c('ul', {
    staticClass: "list-style"
  }, _vm._l(_vm.refundHelperLinks, function (link) {
    return _c('li', {
      key: link.id
    }, [_c('a', {
      staticClass: "hover-underline-animation",
      attrs: {
        "href": "https://www.aof.no/kontakt/brukerveiledning-og-skjema/",
        "target": "_blank"
      }
    }, [_vm._v(_vm._s(link.title))])]);
  }), 0)]), _c('hr'), _c('div', {
    staticStyle: {
      "margin-top": "20px"
    }
  }, [_c('h4', [_c('strong', [_vm._v("Kontakt kundeservice")])]), _c('p', [_vm._v(" Henvend deg til din kompetanserådgiver/kontaktperson dersom du har spørsmål om et konkret kurs eller studie. For andre spørsmål knyttet til bruk av Minside, henvend deg til en av disse stedene: ")]), _vm._l(_vm.districtsSupportInfo, function (district) {
    return _c('div', {
      key: district.id
    }, [_c('h5', [_vm._v(_vm._s(district.district))]), _c('ul', {
      staticClass: "list-style",
      staticStyle: {
        "margin-bottom": "10px"
      }
    }, [_c('li', {
      staticClass: "hover-underline-animation",
      on: {
        "click": function ($event) {
          return _vm.openEmailWindow(district.email);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-email")]), _vm._v(_vm._s(district.email) + " ")], 1), _c('li', [_c('v-icon', [_vm._v("mdi-phone")]), _vm._v(_vm._s(district.phone))], 1)])]);
  })], 2)])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }